import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Link, navigate} from "gatsby";
import Layout from "../components/Layout/Layout";
import {ArrowRightCircleIcon,} from "@heroicons/react/24/outline";
import bannerBg from "../images/banner-background.png";
import bannerSignIn from "../images/banner-signin.png";
import banner from "../images/banner.png";
import banner2 from "../images/banner-2.png";
import banner3 from "../images/banner-3.png";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.png";
import cyber from "../images/cyber.png";
import machine from "../images/machine.png";
import software from "../images/software.png";
import entre from "../images/entre.png";

import {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {Pinneds} from "../components/PinnedCourses/Pinneds";
import {Footer} from "../components/Footer/Footer";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/rootReducer";

const IndexPage = () => {
  const pagination = {
    clickable: true,
    // renderBullet: function (index:string , className:string) {
    //   return '<span class="w-3 h-3 mx-[6px] bg-black rounded-full cursor-pointer ' + className + '"></span>'
    // },
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [itemsIsolated, setItemsIsolated] = useState([]);
  const ScrollCourseRef = useRef<null | HTMLDivElement>(null);
  const handleModal = () => {
    setModalOpen(!modalOpen);
  };
  const ScrollCourseRef2 = useRef<null | HTMLDivElement>(null);

  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const admin =
    typeof window !== "undefined" && localStorage.getItem("isAdmin");
  const [signed, setSigned] = useState(false);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);
  const userElement: any = useSelector((state: RootState) => state.userSlice);
  const handlerScroll = (
    ScrollRef: React.MutableRefObject<HTMLDivElement | null>
  ) => {
    if (ScrollRef.current) {
      ScrollRef.current.scrollLeft += 280;
    }
  };

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true);
      if (admin == "true") {
        navigate("/org-admin");
      }
    }
  }, [loginElement]);
  const firstName = React.useMemo(() => {
    if (userElement.items.user?.firstName.length < 15) {
      return userElement.items.user?.firstName;
    } else {
      return (
          userElement.items.user?.firstName.substring(0, 15) + "..."
      );
    }
  }, [userElement.items.user]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
        {/* header */}
        {/* <Header isSignIn={signed} /> */}

        {signed ? (
          <div
            className="bg-path bg-cover container px-[15px] mx-auto"
            style={{ backgroundImage: `url(${bannerBg})` }}
          >
            <div className="flex flex-col-reverse lg:flex-row gap-4 lg:gap-10 pt-[40px] xl:pt-0">
              <div className="lg:col-span-6 mb-[20px] flex flex-col justify-center lg:mb-0">
                <h2 className="text-[26px] text-center lg:text-start md:text-[34px] lg:text-[54px] mb-4">
                  Welcome,{" "}
                  <span className="ff-cg--semibold">
                    {userElement.items.user !== undefined ? (
                      <>{firstName}</>
                    ) : (
                      ""
                    )}
                    !
                  </span>
                </h2>
                <p className="text-[24px] text-center lg:text-start mb-[20px]">
                  We're ready to help you start, develop, and grow your career
                  by providing the best learning tools and resources you need.{" "}
                  <span className="ff-cg--semibold">Happy learning!</span>
                </p>
                <div className="md:flex justify-center lg:justify-start items-center gap-4">
                  <button className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]">
                    <Link className="flex" to="/learning">
                      <span className="ff-cg--semibold mr-[20px]">
                        See Your Learning
                      </span>
                      <ArrowRightCircleIcon className="h-6 w-6" />
                    </Link>
                  </button>
                  <button className="w-full md:w-fit flex items-center justify-between border border-solid border-black py-[14px] px-[16px] rounded-2xl mt-[30px]">
                    <Link className="flex" to="/search">
                      <span className="ff-cg--semibold mr-[20px]">
                        Explore the Catalog
                      </span>
                      <ArrowRightCircleIcon className="h-6 w-6" />
                    </Link>
                  </button>
                </div>
              </div>
              <div className="lg:col-span-6 flex justify-center items-center">
                <img
                  className="lg:w-full md:w-9/12 object-cover"
                  src={bannerSignIn}
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : (
          <section className="container px-[15px] mx-auto py-[15px] lg:py-[40px] md:pt-[80px] md:pb-[40px]">
            <div className="relative h-[500px] md:h-auto lg:h-[400px] overflow-hidden">
              <div className="swiper-pagination hidden">
                <div className="swiper-pagination-bullet swiper-pagination-bullet-active"></div>
              </div>
              {/* item */}
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={pagination}
                className={"bg-[#222222] rounded-2xl"}
                autoplay={true}
                speed={500}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                spaceBetween={0}
                slidesPerView={1}
              >
                <SwiperSlide className="absolute left-0 right-0 top-0 bottom-0 p-[15px] lg:p-[30px]">
                  <div className="flex flex-col-reverse md:flex-row">
                    <div className="w-full lg:w-[40%] flex flex-col h-full lg:h-[340px] justify-between md:pt-[60px]">
                      <div>
                        <h2 className="ff-cg--semibold text-white text-[26px] lg:text-[37px] leading-none mb-4">
                          What’s Next? It’s Up to You.
                        </h2>
                        <p className="ff-cg--extralight text-white text-base md:text-[20px] lg:text-[30px] leading-none">
                          Face the future with confidence.
                        </p>
                        <p className="ff-cg--extralight text-white text-base md:text-[20px] lg:text-[30px] leading-none">
                          Earn certificates and training in high-demand fields.
                        </p>
                      </div>
                      <Link
                        to="/search"
                        className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]"
                      >
                        <span className="ff-cg--semibold mr-[20px]">
                          Explore Our Offerings
                        </span>
                        <ArrowRightCircleIcon className="h-6 w-6" />
                      </Link>
                    </div>
                    <div className="w-full lg:w-[60%] h-[270px] lg:h-[340px] pt-[30px] md:pt-0 flex items-center justify-start md:justify-center lg:justify-end">
                      <div className="relative">
                        <img
                          className="object-cover h-[200px] lg:h-[320px] lg:mr-[100px]"
                          src={banner}
                          alt=""
                        />
                        <div className="hidden lg:block bg-[#da1a32] text-white py-[15px] px-[18px] rounded-2xl w-[300px] absolute top-[50px] left-[-250px]">
                          <h3 className="ff-cg--bold text-[12px] lg:text-[18px] leading-none">
                            You unlocked a new skill!
                          </h3>
                          <p className="ff-cg--light text-[15px]">
                            Business Analytics
                          </p>
                          <img
                            className="w-[120px] absolute right-[-55px] top-[19px]"
                            src={icon1}
                            alt=""
                          />
                        </div>
                        <div className="bg-[#fdbf38] pl-[60px] lg:pl-[40px] pr-[13px] pt-[10px] pb-[10px] lg:py-[18px] px-[15px] rounded-2xl w-[250px] lg:w-[300px] absolute right-[-100px] md:right-[-50px] bottom-[20px] lg:bottom-0 lg:right-0">
                          <h3 className="ff-cg--bold text-[12px] lg:text-[18px] leading-none">
                            You landed a new interview!
                          </h3>
                          <p className="ff-cg--medium text-[10px] lg:text-[15px]">
                            Cybersecurity
                          </p>
                          <img
                            className="w-[70px] absolute left-[-22px] top-[-12px] lg:left-[-42px] lg:top-0"
                            src={icon2}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="absolute left-0 right-0 top-0 bottom-0 p-[15px] lg:p-[30px]">
                  <div className="flex flex-col-reverse md:flex-row">
                    <div className="w-full lg:w-[40%] flex flex-col h-full lg:h-[340px] justify-between md:pt-[60px]">
                      <div>
                        <h2 className="ff-cg--semibold text-white text-[26px] lg:text-[37px] leading-none mb-4">
                          Transform Your Organization
                        </h2>
                        <p className="ff-cg--extralight text-white text-base md:text-[20px] lg:text-[30px] leading-none">
                          Customize a skills-focused solution for building a
                          stronger, more effective workforce.
                        </p>
                      </div>
                      <Link
                        to="/business"
                        className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]"
                      >
                        <span className="ff-cg--semibold mr-[20px]">
                          Learn More
                        </span>
                        <ArrowRightCircleIcon className="h-6 w-6" />
                      </Link>
                    </div>
                    <div className="w-full lg:w-[60%] h-[270px] lg:h-[340px] pt-[30px] md:pt-0 flex items-center justify-start md:justify-center lg:justify-end">
                      <div className="lg:absolute lg:top-0 lg:bottom-0 lg:right-0">
                        <img
                          className="object-cover w-[200px] h-[200px] rounded-full lg:rounded-none mr-0 ml-auto lg:w-auto lg:h-full"
                          src={banner2}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="absolute left-0 right-0 top-0 bottom-0 p-[15px] lg:p-[30px]">
                  <div className="flex flex-col-reverse md:flex-row">
                    <div className="w-full lg:w-[40%] flex flex-col h-full lg:h-[340px] justify-between md:pt-[60px]">
                      <div>
                        <h2 className="ff-cg--semibold text-white text-[26px] lg:text-[37px] leading-none mb-4">
                          Shape the Future with Us
                        </h2>
                        <p className="ff-cg--extralight text-white text-base md:text-[20px] lg:text-[30px] leading-none">
                          Expand your reach with a global skills marketplace
                          backed by one of the world's largest online
                          universities.
                        </p>
                      </div>
                      <Link
                        to="/contact"
                        className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]"
                      >
                        <span className="ff-cg--semibold mr-[20px]">
                          Contact Us
                        </span>
                        <ArrowRightCircleIcon className="h-6 w-6" />
                      </Link>
                    </div>

                    <div className="w-full lg:w-[60%] h-[270px] lg:h-[340px] pt-[30px] md:pt-0 flex items-center justify-start md:justify-center lg:justify-end">
                      <div className="lg:absolute lg:top-0 lg:bottom-0 lg:right-0">
                        <img
                          className="object-cover w-[200px] h-[200px] rounded-full lg:rounded-none lg:w-auto lg:h-full"
                          src={banner3}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        )}
        {/* banner */}

        {/* title button */}
        <section className="container px-[15px] mx-auto py-[30px] lg:py-[50px]">
          <div className="flex justify-between">
            <div className="w-full mr-[30px]">
              <h3 className="text-[30px] lg:text-[35px] ff-cg--semibold leading-none">
                Jumpstart a Career in an In-Demand Field
              </h3>
              <p className="font-light text-[18px] lg:text-[24px]">
                Expand your skills or break into a new field such as IT,
                cybersecurity, business, or health sciences.
              </p>
            </div>
            <div className="hidden lg:block w-[300px]">
              <Link
                to="/search"
                className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[15px] lg:mr-0 lg:ml-auto"
              >
                <span className="ff-cg--semibold mr-[20px]">
                  Explore Certificates
                </span>
                <ArrowRightCircleIcon className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </section>

        {/* list products */}
        <section className="container pl-[15px] pr-[15px] lg:px-[15px] mx-auto pt-[20px] md:pb-10 lg:pb-0">
          <div className="relative">
            <div
              className="flex lg:grid gap-4 lg:gap-10 lg:grid-cols-12 pb-4 overflow-x-auto scroll-smooth"
              ref={ScrollCourseRef}
            >
              <Pinneds uuid={process.env.CERTS_ONE || ""} />
              <Pinneds uuid={process.env.CERTS_TWO || ""} />
              <Pinneds uuid={process.env.CERTS_THREE || ""} />
            </div>
            <div className="absolute lg:hidden right-[30px] top-[190px]">
              <button
                className=" lg:w-fit flex items-center justify-center bg-[#fdbf38] w-16 h-16 rounded-full"
                onClick={(e) => {
                  handlerScroll(ScrollCourseRef);
                }}
              >
                <ArrowRightCircleIcon className="h-12 w-12" />
              </button>
            </div>
          </div>
        </section>

        {/* title button */}
        <section className="container px-[15px] mx-auto py-[30px] lg:py-[50px]">
          <div className="flex justify-between">
            <div className="w-full mr-[30px]">
              <h3 className="text-[30px] lg:text-[35px] ff-cg--semibold leading-none">
                Gain New Skills Today for a Better Tomorrow
              </h3>
              <p className="font-light text-[18px] lg:text-[24px]">
                A variety of courses in topics like cybersecurity, leadership,
                project management, and more.
              </p>
            </div>
            <div className="hidden lg:block w-[300px]">
              <Link
                to="/search"
                className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[15px] lg:mr-0 lg:ml-auto"
              >
                <span className="ff-cg--semibold mr-[20px]">Start Now</span>
                <ArrowRightCircleIcon className="h-6 w-6" />
              </Link>
            </div>
          </div>
        </section>

        {/* list products */}
        <section className="container pl-[15px] lg:px-[15px] mx-auto pt-[20px]">
          <div className="relative">
            <div
              className="flex lg:grid gap-4 lg:gap-10 lg:grid-cols-12 pb-4 overflow-x-auto pr-[15px] lg:pr-0 scroll-smooth"
              ref={ScrollCourseRef2}
            >
              <Pinneds uuid={process.env.COURSE_ONE || ""} />

              <Pinneds uuid={process.env.COURSE_TWO || ""} />
              <Pinneds uuid={process.env.COURSE_THREE || ""} />
            </div>
            <div className="absolute lg:hidden right-[30px] top-[190px]">
              <button
                className="w-full lg:w-fit flex items-center justify-center bg-[#fdbf38] w-16 h-16 rounded-full"
                onClick={(e) => {
                  handlerScroll(ScrollCourseRef2);
                }}
              >
                <ArrowRightCircleIcon className="h-12 w-12" />
              </button>
            </div>
          </div>
        </section>

        <section className="bg-[#222222] mt-[-80px]">
          {/* title button */}
          <section className="container px-[15px] mx-auto pb-[20px] lg:pb-[50px] pt-[160px]">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="ff-cg--semibold text-white text-[30px] lg:text-[35px] leading-none">
                  Explore Workforce Forward
                </h3>
                <p className="font-light text-[18px] lg:text-[24px] text-white">
                  Find the skills you need to advance in your career.
                </p>
              </div>
              {/* <div className="hidden lg:block">
                <button className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px] lg:mt-0">
                  <Link to="/search">
                    <span className="ff-cg--semibold mr-[20px]">Go to All Paths</span>
                    <ArrowRightCircleIcon className="h-6 w-6"/>
                  </Link>
                </button>
              </div> */}
            </div>
          </section>

          {/* list products */}
          <section className="container px-[15px] mx-auto pt-[20px]">
            <div className="pb-[40px] lg:pb-[100px]">
              <div className="grid gap-6 lg:gap-10 grip-cols-4 lg:grid-cols-8">
                <div className="max-[350px]:w-[290px] lg:min-w-fit col-span-4 ">
                  <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between min-h-[220px] relative">
                    <h4 className="max-[350px]:text-[32px] text-[40px] lg:text-[40px] ff-cg--semibold leading-none mt-[20px]">
                      Business
                    </h4>
                    <Link to="/search?category=business">
                      <button className="w-fit flex items-center justify-between bg-[#fdbf38] py-[6px] px-[16px] rounded-full mt-[30px]">
                        <span className="ff-cg--semibold">Learn More</span>
                      </button>
                    </Link>
                    <img
                      className="w-[200px] bottom-0 right-0 absolute"
                      src={entre}
                      alt=""
                    />
                  </div>
                </div>
                <div className="max-[350px]:w-[290px] lg:min-w-fit col-span-4">
                  <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between min-h-[220px] relative">
                    <h4 className="max-[350px]:text-[32px] text-[40px] lg:text-[40px] ff-cg--semibold leading-none mt-[20px] capitalize">
                      Cybersecurity
                    </h4>
                    <Link to="/search?category=cybersecurity">
                      <button className="w-fit flex items-center justify-between bg-[#fdbf38] py-[6px] px-[16px] rounded-full mt-[30px]">
                        <span className="ff-cg--semibold">Learn More</span>
                      </button>
                    </Link>
                    <img
                      className="w-[200px] bottom-0 right-0 absolute"
                      src={cyber}
                      alt=""
                    />
                  </div>
                </div>
                <div className="max-[350px]:w-[290px] lg:min-w-fit col-span-4">
                  <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between min-h-[220px] relative">
                    <h4 className="max-[350px]:text-[32px] text-[40px] lg:text-[40px] ff-cg--semibold leading-none mt-[20px] w-56 lg:w-full">
                      Information Technology
                    </h4>
                    <Link to="/search?category=information-technology">
                      <button className="w-fit flex items-center justify-between bg-[#fdbf38] py-[6px] px-[16px] rounded-full mt-[30px]">
                        <span className="ff-cg--semibold">Learn More</span>
                      </button>
                    </Link>
                    <img
                      className="max-[350px]:w-[280px] w-[360px] bottom-0 right-0 absolute"
                      src={software}
                      alt=""
                    />
                  </div>
                </div>
                <div className="max-[350px]:w-[290px] lg:min-w-fit col-span-4">
                  <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between min-h-[220px] relative">
                    <h4 className="max-[350px]:text-[32px] text-[40px] lg:text-[40px] ff-cg--semibold leading-none mt-[20px]">
                      Health Sciences
                    </h4>
                    <Link to="/search?category=health-sciences">
                      <button className="w-fit flex items-center justify-between bg-[#fdbf38] py-[6px] px-[16px] rounded-full mt-[30px]">
                        <span className="ff-cg--semibold">Learn More</span>
                      </button>
                    </Link>
                    <img
                      className="max-[350px]:w-[112px] w-[140px] bottom-0 right-0 absolute"
                      src={machine}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* <section className="container px-[15px] mx-auto py-[40px] lg:py-[80px]">
          <div className="">/courses/test-gg
            <div className="grid gap-6 lg:gap-10 lg:grid-cols-12 items-center">
              <div className="lg:col-span-4 order-2 lg:order-1 flex items-center flex-col text-center lg:text-left">
                <h3 className="text-[20px] lg:text-[70px] ff-cg--semibold leading-none mb-[10px] lg:mb-[20px]">Learning With Experts</h3>
                <p className="text-[16px] lg:text-[50px] ff-cg--light">Meet faculty from UMGC who'll share their experience through videos, articles, quizzes and discussions.</p>
              </div>
              <div className="lg:col-span-8 order-1">
                <img className="object-cover w-full" src={ imgLarge } alt="" />
              </div>
            </div>
          </div>
        </section> */}

        {/* footer */}
        <Footer />
      </div>
      {/* {
        (modalOpen) ?
        <Modal handleModal={handleModal} /> : ""
      } */}
    </Layout>
  );
};

export default IndexPage;

export { Head } from "../components/Layout/Head";

// export const Head: HeadFC = () => <title>Home Page</title>;
